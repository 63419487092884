const menuBtn        = document.querySelector('.menuBtn'),
      menuBlock      = document.querySelector('.menuBlock'),
      overlay        = document.querySelector('.overlay'),
      menuLinks      = document.querySelectorAll('.link'),
      documentWidth  = parseInt(document.documentElement.clientWidth),
      windowWidth    = parseInt(window.innerWidth), 
      scrollbarWidth = windowWidth - documentWidth,
      innersMenu     = document.querySelectorAll('.inner-open-link'),
      innerLinks     = document.querySelectorAll('.inner-link'),
      header         = document.querySelector('.header'),
      upBtn          = document.querySelector('.up')


menuBtn.addEventListener('click', (e) => {
    e.preventDefault()
    menuBtn.classList.toggle('open')
    menuBlock.classList.toggle('open')
    overlay.classList.toggle('visible')

    innersMenu.forEach((inner) => {
        inner.parentNode.classList.remove('open')
    })

    if(menuBtn.classList.contains('open')) {
        document.body.style.overflowY = 'hidden'
        document.body.style.marginRight = `${scrollbarWidth}px`
        header.style.width = `calc(100% - ${scrollbarWidth}px)`
        upBtn.style.right = `calc(60px + ${scrollbarWidth}px`
    } else {
        document.body.style.overflowY = ''
        document.body.style.marginRight = ''
        header.style.width = ''
        upBtn.style.right = ''
    }
})

overlay.addEventListener('click', () => {
    menuBtn.classList.remove('open')
    menuBlock.classList.remove('open')
    overlay.classList.remove('visible')
    document.body.style.overflowY = ''
    document.body.style.marginRight = ''
    header.style.width = ''
    // upBtn.style.right =''

    innersMenu.forEach((inner) => {
        inner.parentNode.classList.remove('open')
    })
})

menuLinks.forEach((link) => {
    link.addEventListener('mouseover', () => {
        innersMenu.forEach((i) => {
            i.parentNode.classList.remove('open')
        })
    })

    link.addEventListener('click', () => {
        if(!link.parentNode.parentNode.classList.contains('inner-menu')){
            menuBtn.classList.remove('open')
            menuBlock.classList.remove('open')
            overlay.classList.remove('visible')
            document.body.style.overflowY = ''
            document.body.style.marginRight = ''
            header.style.width = ''
            // upBtn.style.right = ''

            innersMenu.forEach((inner) => {
                inner.parentNode.classList.remove('open')
            })
        }
    })
})

innersMenu.forEach((inner) => {
    inner.addEventListener('mouseover', (e) => {
        e.preventDefault()
       
        innersMenu.forEach((i) => {
            i.parentNode.classList.remove('open')
        })

        inner.parentNode.classList.add('open')        
    })

	inner.addEventListener('click', e => {
		e.preventDefault()
	})
})

// innerLinks.forEach((link) => {
//     link.addEventListener('click', (e) => {
//         menuBtn.classList.remove('open')
//         menuBlock.classList.remove('open')
//         overlay.classList.remove('visible')
//         document.body.style.overflowY = ''
//         document.body.style.marginRight = ''

//         innersMenu.forEach((inner) => {
//             inner.parentNode.classList.remove('open')
//         })
//     })
// })

