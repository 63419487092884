window.addEventListener("load", () => {
    if (document.documentElement.clientWidth < 992) {

    const sectionContent = document.querySelector("[data-section='content']"),
          blocksImage = document.querySelectorAll(".image-block");

    blocksImage.forEach(img => {
        const height = img.parentNode.clientHeight,
        newDiv = document.createElement("div"),
        newDivOverlay = document.createElement("div"),
        parentPos = sectionContent.getBoundingClientRect(),
        childrenPos = img.getBoundingClientRect(),
        relativePos = {},

        // windowInnerWidth = window.innerWidth,
        // containerWidth = document.querySelector(".container").clientWidth,
        // marginContainer = (windowInnerWidth - containerWidth) / 2 - 8.5,
        // blockWidth = img.parentNode.clientWidth + (windowInnerWidth - containerWidth) / 2,
        
        dataImage = img.getAttribute("data-src")
        // ifColXxl6 = img.parentNode.classList.contains("col-xxl-6"),
        // ifColXxl12 = img.parentNode.classList.contains("col-xxl-12")
        

        // let colWidth     = '',
        //     leftOrRight  = ''

        relativePos.top = childrenPos.top - parentPos.top;
        // relativePos.left = childrenPos.left - parentPos.left;
        // relativePos.right = childrenPos.right - parentPos.right;

        //     if(relativePos.left == marginContainer || relativePos.left == marginContainer + 20.5) {
        //         leftOrRight = `left: 0;`
        //     } else if(relativePos.right == -marginContainer || relativePos.right == -(marginContainer + 20.5)) {
        //         leftOrRight = `right: 0;`
        //     }

        //     if (ifColXxl6) {
        //         if (leftOrRight == 'left: 0;') {
        //             colWidth = "width: calc(50% + 12px);";
        //         } else {
        //             colWidth = "width: calc(50% - 12px);";
        //         }
        //     } else if (ifColXxl12) {
        //         colWidth = "width: 100%;";
        //     } else {
        //         if (leftOrRight == 'left: 0;' || leftOrRight == 'right: 0;') {
        //             colWidth = `width: ${blockWidth+6}px;`;
        //         }
        //     }

        newDivOverlay.style.cssText = `
                            position: absolute;
                            background-color: rgba(14,26,94,0.35);
                            top: ${relativePos.top}px;
                            left: 0;
                            width: 100%;
                            height: ${height}px;
                            z-index: 1;
                            `;

        newDiv.style.cssText = `position: absolute;
                            background-image: url(${dataImage});
                            background-repeat: no-repeat;
                            background-size: cover;
                            top: ${relativePos.top}px;
                            left: 0;
                            width: 100%;
                            height: ${height}px;
                            z-index: -1;
                            `;

        sectionContent.append(newDivOverlay);
        sectionContent.append(newDiv);
    });

    }
})