const callbackBtn     = document.querySelector('.callback-btn'),
      callbackModal   = document.querySelector('.callback-modal'),
      callbackOverlay = document.querySelector('.callback-overlay'),
      callbackClose   = document.querySelector('.callback-close-btn')        

callbackBtn.addEventListener('click', e => {
    e.preventDefault
    callbackModal.classList.add('open')
})

callbackOverlay.addEventListener('click', e => {
    e.preventDefault
    callbackModal.classList.remove('open')
})

callbackClose.addEventListener('click', e => {
    e.preventDefault
    callbackModal.classList.remove('open')
})