function wrapper() {
    const   sectionContent = document.querySelector("[data-section='content']"),
            blocksClororLightblue = document.querySelectorAll(".lightblue"),
            blocksClororBlue = document.querySelectorAll(".blue"),
            blocksImage = document.querySelectorAll(".image-block");

    blocksImage.forEach((imageBlock) => {
        imageBlock.parentNode.classList.add("parent-image-block");
    });

    blocksClororLightblue.forEach((lbElement) => {
        const height = lbElement.parentNode.clientHeight,
        newDiv = document.createElement("div"),
        parentPos = sectionContent.getBoundingClientRect(),
        childrenPos = lbElement.getBoundingClientRect(),
        relativePos = {},
        windowInnerWidth = window.innerWidth,
        containerWidth = document.querySelector(".container").clientWidth,
        blockWidth = lbElement.parentNode.clientWidth + ((windowInnerWidth - containerWidth) / 2),
        marginContainer = (windowInnerWidth - containerWidth) / 2 + 3.5,
        ifColXxl6 = lbElement.parentNode.classList.contains("col-xxl-6"),
        ifColXxl12 = lbElement.parentNode.classList.contains("col-xxl-12");

        let colWidth     = '',
            leftOrRight  = ''

        relativePos.top   = childrenPos.top - parentPos.top;
        relativePos.left  = childrenPos.left - parentPos.left;
        relativePos.right = childrenPos.right - parentPos.right;


        if(relativePos.left == marginContainer + 5.5 || relativePos.left == marginContainer + 14) {
            leftOrRight = `left: 0;`
        } else if(relativePos.right == -(marginContainer + 5.5) || relativePos.right == -(marginContainer + 14)) {
            leftOrRight = `right: 0;`
        } else {

        }

        if (ifColXxl6) {
        if (leftOrRight == 'left: 0;') {
            colWidth = "width: calc(50% + 12px);";
        } else {
            colWidth = "width: calc(50% - 12px);";
        }
        } else if (ifColXxl12) {
        colWidth = "width: 100%;";
        } else {
        if (leftOrRight == 'left: 0;' || leftOrRight == 'right: 0;') {
            colWidth = `width: calc(${blockWidth+6}px);`;
        }
        }

        newDiv.style.cssText = `position: absolute;
                                background-color: #F5F7FF;
                                top: ${relativePos.top}px;
                                ${leftOrRight}
                                ${colWidth}
                                height: ${height}px;
                                z-index: -1;
                                `;

    //   newDiv.setAttribute("data-block", `ГОЛУБОЙ-${idx + 1}`);
        
        newDiv.setAttribute("data-block", `wrapper`);

        sectionContent.append(newDiv);
    });

    blocksClororBlue.forEach((blueElement) => {
        const height = blueElement.parentNode.clientHeight,
        newDiv = document.createElement("div"),
        parentPos = sectionContent.getBoundingClientRect(),
        childrenPos = blueElement.getBoundingClientRect(),
        relativePos = {},
        windowInnerWidth = window.innerWidth,
        containerWidth = document.querySelector(".container").clientWidth,
        blockWidth =
            blueElement.parentNode.clientWidth +
            (windowInnerWidth - containerWidth) / 2,
        marginContainer = (windowInnerWidth - containerWidth) / 2 + 3.5,
        ifColXxl6 = blueElement.parentNode.classList.contains("col-xxl-6"),
        ifColXxl12 = blueElement.parentNode.classList.contains("col-xxl-12");             

        let colWidth     = '',
            leftOrRight  = ''

        relativePos.top = childrenPos.top - parentPos.top;
        relativePos.left = childrenPos.left - parentPos.left;
        relativePos.right = childrenPos.right - parentPos.right;



    //   console.log(`rel: ${relativePos.right} = mrg: ${marginContainer}`);


        if(relativePos.left == marginContainer + 5.5 || relativePos.left == marginContainer + 14) {
            leftOrRight = `left: 0;`
        } else if(relativePos.right == -(marginContainer + 5.5) || relativePos.right == -(marginContainer + 14)) {
            leftOrRight = `right: 0;`
        } 

        if (ifColXxl6) {
            if (leftOrRight == 'left: 0;') {
                colWidth = "width: calc(50% + 12px);";
                // blueElement.classList.add('pr120')
                // blueElement.style.cssText = "padding-right: 120px;"
            } else {
                colWidth = "width: calc(50% - 12px);";
                // blueElement.style.cssText = "padding-left: 120px;"
                // blueElement.classList.add('pl120')
            }
            } else if (ifColXxl12) {
            colWidth = "width: 100%;";
            } else {
            if (leftOrRight == 'left: 0;' || leftOrRight == 'right: 0;') {
                colWidth = `width: ${blockWidth+6}px;`;
            } else {

            }
        }

        newDiv.style.cssText = `position: absolute;
                                background-color: #384ECB;
                                top: ${relativePos.top}px;
                                ${leftOrRight}
                                ${colWidth}
                                height: ${height}px;
                                z-index: -1;
                                `;

        newDiv.setAttribute("data-block", `wrapper`);

        sectionContent.append(newDiv);
    });

    blocksImage.forEach((img) => {
        const height = img.parentNode.clientHeight,
        newDiv = document.createElement("div"),
        newDivOverlay = document.createElement("div"),
        parentPos = sectionContent.getBoundingClientRect(),
        childrenPos = img.getBoundingClientRect(),
        relativePos = {},

        windowInnerWidth = window.innerWidth,
        containerWidth = document.querySelector(".container").clientWidth,
        marginContainer = (windowInnerWidth - containerWidth) / 2 - 8.5,
        blockWidth = img.parentNode.clientWidth + (windowInnerWidth - containerWidth) / 2,
        
        dataImage = img.getAttribute("data-src"),
        ifColXxl6 = img.parentNode.classList.contains("col-xxl-6"),
        ifColXxl12 = img.parentNode.classList.contains("col-xxl-12")
        

        let colWidth     = '',
            leftOrRight  = ''

        relativePos.top = childrenPos.top - parentPos.top;
        relativePos.left = childrenPos.left - parentPos.left;
        relativePos.right = childrenPos.right - parentPos.right;

            if(relativePos.left == marginContainer + 5.5 || relativePos.left == marginContainer + 26) {
                leftOrRight = `left: 0;`
            } else if(relativePos.right == -(marginContainer + 5.5) || relativePos.right == -(marginContainer + 26)) {
                leftOrRight = `right: 0;`
            }

            if (ifColXxl6) {
                if (leftOrRight == 'left: 0;') {
                    colWidth = "width: calc(50% + 12px);";
                } else {
                    colWidth = "width: calc(50% - 12px);";
                }
            } else if (ifColXxl12) {
                colWidth = "width: 100%;";
            } else {
                if (leftOrRight == 'left: 0;' || leftOrRight == 'right: 0;') {
                    colWidth = `width: ${blockWidth+6}px;`;
                }
            }

        if (document.documentElement.clientWidth > 992) {
            newDivOverlay.style.cssText = `position: absolute;
                                        background-color: rgba(14,26,94,0.35);
                                        top: ${relativePos.top}px;
                                        ${leftOrRight}
                                        ${colWidth}
                                        height: ${height}px;
                                        z-index: 1;
                                        `;
        }

        newDiv.style.cssText = `position: absolute;
                                background-image: url(${dataImage});
                                background-repeat: no-repeat;
                                background-size: cover;
                                top: ${relativePos.top}px;
                                ${leftOrRight}
                                ${colWidth} 
                                height: ${height}px;
                                z-index: -1;
                                `;

        if (newDivOverlay) {
            newDivOverlay.setAttribute("data-block", `wrapper`);
        }
        
        newDiv.setAttribute("data-block", `wrapper`);

        sectionContent.append(newDivOverlay);
        sectionContent.append(newDiv);
    });

}

window.addEventListener("load", () => {
    
    if (document.documentElement.clientWidth > 992) {
  
        wrapper()

    }
    
});


document.addEventListener("DOMContentLoaded", function() {
    window.onresize = function() {

        const wp = document.querySelectorAll("[data-block='wrapper']")

        wp.forEach((block) => {
            block.remove();
        })
        wrapper()
    };
});