ymaps.ready(init);

function init() {
    var myMap = new ymaps.Map("map", {
        center: [54.2261988732502, 37.604069669341634],
        zoom: 17,
        controls: ["zoomControl", "fullscreenControl"]
      }),
  
      // Создаем метку с помощью вспомогательного класса.
      myPlacemark1 = new ymaps.Placemark([54.2261988732502, 37.604069669341634], {
        // Свойства.
        // Содержимое хинта.
        hintContent: 'АО НПК промышленые технологии'//'Надпись, которая всплаывет при наведении на метку'
      }, {
        // Опции
        // Своё изображение иконки метки.
        iconImageHref: 'https://static.tildacdn.com/tild3061-3235-4537-b066-616662373363/Group_783.svg',
        // Размеры метки.
        iconImageSize: [130, 130],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-65, -110]
      })
    
  
    // Добавляем все метки на карту.
    myMap.geoObjects
      .add(myPlacemark1)

    myMap.behaviors.disable('scrollZoom')
  
  }